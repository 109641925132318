<template>
  <div class="mt-70-px">
    <navbar title="Rapport"></navbar>
    <div v-if="angemeldeterMitarbeiter && !isAdmin" class="container-fluid tile-container">
      <div class="row mt-5">
        <div class="col-xl-4 col-lg-12">
          <div class="row pt-1">
            <div class="col-lg-12">
              <div class="tile-categorie-frame">
                <div class="tile-categorie">
                  <div class="header">
                    <span>Rapport</span>
                  </div>
                  <div class="body">
                    <div class="row">
                      <div class="col-12">
                        <div class="tile-frame h-1 left-aligned">
                          <router-link :to="{ name: 'schichtrapport', params: {id: openShiftReport}}">
                            <div class="tile bg-light-blue inverted">
                              <div class="icon-container fa-container">
                                <font-awesome-icon
                                  icon="fa-duotone fa-box"
                                  transform="shrink-2"
                                />
                              </div>
                              <div class="title">Schichtrapport</div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="tile-frame h-1 left-aligned">
                          <router-link :to="{ name: 'ereignisrapport', params: {id: openEventReport} }">
                            <div class="tile bg-light-blue inverted">
                              <div class="icon-container fa-container">
                                <font-awesome-icon
                                  icon="fa-duotone fa-boxes-stacked"
                                  transform="shrink-2 left-1"
                                />
                              </div>
                              <div class="title">Ereignisrapport</div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="angemeldeterMitarbeiter && isAdmin" class="container-fluid tile-container">
      <div class="row mt-5">
        <div class="col-xl-4 col-lg-12">
          <div class="row pt-1">
            <div class="col-lg-12">
              <div class="tile-categorie-frame">
                <div class="tile-categorie">
                  <div class="header">
                    <span>Rapport</span>
                  </div>
                  <div class="body">
                    <div class="row">
                      <div class="col-12">
                        <div class="tile-frame h-1 left-aligned">
                          <router-link :to="{ name: 'schichtrapport', params: {id: openShiftReport}}">
                            <div class="tile bg-light-blue inverted">
                              <div class="icon-container fa-container">
                                <font-awesome-icon
                                  icon="fa-duotone fa-box"
                                  transform="shrink-2"
                                />
                              </div>
                              <div class="title">Schichtrapport</div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="tile-frame h-1 left-aligned">
                          <router-link :to="{ name: 'ereignisrapport', params: {id: openEventReport} }">
                            <div class="tile bg-light-blue inverted">
                              <div class="icon-container fa-container">
                                <font-awesome-icon
                                  icon="fa-duotone fa-boxes-stacked"
                                  transform="shrink-2 left-1"
                                />
                              </div>
                              <div class="title">Ereignisrapport</div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
	
	<script>
import page from "@/mixins/Page";
import Navbar from "@/components/Navbar";
import store from "@/store";
import Rapport from "@/Rapport";

import Mitarbeiter from "@/models/Mitarbeiter";

export default {
  name: "Startseite",
  components: {
    Navbar,
  },
  mixins: [page],
  store,
  metaInfo() {
    return {
      titleTemplate: () => {},
    };
  },
  data() {
    return {
      name: "",
      isAdmin: false,
      openShiftReport: null,
      openEventReport: null
    };
  },
  computed: {},
  mounted: function () {
   this.isAdmin =
      this.gruppen.findIndex(
        (gruppe) => gruppe.id == "1774DD13671"
      ) == -1
        ? false
        : true;
  },
  created() {
    this.name = Mitarbeiter.fullName(this.angemeldeterMitarbeiter);

    Rapport.get("shiftreport/" + this.angemeldeterMitarbeiter.id).then(
        (response) => {
          console.log(response);
          this.openShiftReport = response.data.id;
        }
      );

      Rapport.get("eventreport/" + this.angemeldeterMitarbeiter.id).then(
        (response) => {
          console.log(response);
          this.openEventReport = response.data.id;
        }
      );
  },

};
</script>
	
	<style lang="scss">
</style>
	